import React from 'react'
import Hero from 'src/components/contactSections/hero'

import Layout from 'src/components/layout/layout'
import Seo from 'src/components/seo'

interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = () => (
  <Layout>
    <Seo
      title='Kontakt - JM Car Company'
      description='Porozmawiajmy. Zadaj nam pytanie lub złóż zamówienie na auto, którego nie ma w ofercie. Służymy pomocą i doświadczeniem.'
    />
    <Hero />
  </Layout>
)

export default ContactPage
