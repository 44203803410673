import React from 'react'
import Form from '../form/form'

import * as S from './hero.styles'

interface HeroProps {}

const Hero: React.FC<HeroProps> = () => (
  <S.Hero>
    <S.Header>Napisz wiadomość</S.Header>
    <S.SubHeader>
      Zadaj nam pytanie lub złóż zamówienie na auto, którego nie ma w ofercie. Odezwiemy się do Ciebie z ofertą
      indywidualną.
    </S.SubHeader>
    <S.Paragraph>Złożenie zamówienia nie zobowiązuje Cię do zakupu.</S.Paragraph>
    <Form />
  </S.Hero>
)

export default Hero
