import styled from 'styled-components'

export const Hero = styled.section`
  max-width: 108rem;
  margin: 0 auto;
  padding: 5rem 3.7rem 0;
  color: ${({ theme }) => theme.darkgray};
  text-align: center;

  ${({ theme }) => theme.mq.tablet} {
    padding: 8rem 3.7rem 0;
  }
`

export const Header = styled.h1`
  position: relative;
  padding-top: 4rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.l};

  ::after {
    position: absolute;
    bottom: -0.9rem;
    left: 50%;
    width: 9rem;
    height: 0.2rem;
    background: ${({ theme }) => theme.red};
    transform: translateX(-50%);
    content: '';
  }
`
export const SubHeader = styled.h2`
  display: block;
  max-width: 61rem;
  margin: 2.2rem auto 2rem;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.headers.s};
`
export const Paragraph = styled.p`
  margin-bottom: 4rem;
`
